/**
 * ExtractParam.js
 * Get value from url params: url.com?locale=sv_SE
 * Example: ExtractParam("locale") -> sv_SE
 */
const ExtractParam = param => {
  let search = window.location.search.substr(1).split("&");
  let res = {};
  for (let i = 0; i < search.length; ++i) {
    var p = search[i].split("=");
    if (p.length !== 2) {
      continue;
    }
    res[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  }
  return res[param];
};

export default ExtractParam;
