/* global window, document, console, navigator, AndroidAdMessageHandler */
export default (function() {
  var DeviceBridge = function() {};

  var isVisible = false;

  var apiVersion = (function() {
    var apiVersionParam =
      (window.forzaProperties && window.forzaProperties.apiVersion) || 20;
    // Handle clients not replacing the in-URL param as v1 clients
    if (apiVersionParam === "__DEVICE_BRIDGE_VERSION__") {
      return 1;
    }
    return parseInt(apiVersionParam, 10);
  })();

  var postToDevice = function(action, params) {
    if (isNaN(apiVersion) || 20 > apiVersion) {
      if (console && console.warn) {
        console.warn("Posting objects to device requires apiVersion > 20");
      }
      return;
    }

    var message = {
      action: action,
      params: params
    };

    if (DeviceBridge.devicePlatform()[0] === "ios") {
      window.webkit.messageHandlers.ads.postMessage(message);
    } else {
      AndroidAdMessageHandler.postMessage(JSON.stringify(message));
    }
  };

  var callDevice = function(method, requiredApiVersion) {
    if (isNaN(apiVersion) || requiredApiVersion > apiVersion) {
      if (console && console.warn) {
        console.warn("bad apiVersion");
      }
      return;
    }

    var f = document.createElement("iframe");
    f.src = "footballaddicts://" + method;
    f.style.display = "none";
    document.body.appendChild(f);
  };

  DeviceBridge.setAdvertTargetingUserData = function(user_data) {
    postToDevice("update_advert_targeting", user_data);
  };

  DeviceBridge.displayAd = function(height) {
    if (isNaN(apiVersion) || apiVersion < 8 || !height || isNaN(height)) {
      callDevice("display_ad", 1);
    } else {
      callDevice("display_ad?height=" + height, 8);
    }

    isVisible = true;
    // Fake callback
    if (
      (isNaN(apiVersion) || apiVersion < 3) &&
      typeof window.didDisplayAd === "function"
    ) {
      window.didDisplayAd();
    }
  };

  DeviceBridge.hideAd = function() {
    if (DeviceBridge.devicePlatform()[0] === "ios" && isVisible === false) {
      this.displayAd(1);
    }

    callDevice("hide_ad", 2);
    // Fake callback
    if (
      (isNaN(apiVersion) || apiVersion < 3) &&
      typeof window.didHideAd === "function"
    ) {
      window.didHideAd();
    }
  };

  DeviceBridge.resizeAd = function(height) {
    if (!height) {
      console.error("No height given");
      return;
    }
    callDevice("resize_ad?height=" + height, 8);
  };

  DeviceBridge.set = function(key, value) {
    callDevice("set?" + key + "=" + value, 9);
  };

  /* Hackish way to get log messages to iPhone when nothing else works */
  DeviceBridge.debugLog = function(message) {
    callDevice("set?log=" + encodeURIComponent(message), 16);
  };

  /* Used for functions that needs specified api version */
  DeviceBridge.internalSet = function(key, value, api_version) {
    callDevice("set?" + key + "=" + value, api_version);
  };

  DeviceBridge.openTheme = function(ident) {
    callDevice("open_theme?ident=" + ident, 10);
  };

  DeviceBridge.sendSMS = function(number, text) {
    callDevice("sms?nr=" + number + "&text=" + encodeURIComponent(text), 17);
  };

  // Only partner third party apps
  DeviceBridge.thirdPartyApp = function(appName) {
    callDevice("third-party-app?name=" + appName, 17);
  };

  // Native share dialog
  DeviceBridge.nativeShare = function(shareObj) {
    var urlParams = "?url=" + encodeURIComponent(shareObj.url);
    if (shareObj.content) {
      urlParams += "&content=" + encodeURIComponent(shareObj.content);
    }
    if (shareObj.image) {
      urlParams += "&image=" + encodeURIComponent(shareObj.image);
    }
    if (shareObj.screenshot) {
      urlParams += "&screenshot=true";
    }
    callDevice("native_share" + urlParams, 18);
  };

  DeviceBridge.pushSignup = function(pushService, subjectId, subjectType) {
    callDevice(
      "push_signup?push_service=" +
        pushService +
        "&subject_id=" +
        subjectId +
        "&subject_type=" +
        subjectType,
      11
    );
  };

  DeviceBridge.getPushToken = function() {
    callDevice("get_push_token", 19);
  };

  DeviceBridge.apiVersion = function() {
    return apiVersion;
  };

  /* Gets the device 'brand'/type and platform
   * If platform is defined return null
   *
   * Returns:
   * [ios, phone] or [ios, pad] or [android, phone] or [android, pad]
   */
  DeviceBridge.devicePlatform = function() {
    var platform = window.forzaProperties.platform;
    if (platform === undefined) {
      return null;
    }
    if (platform === "__PLATFORM__") {
      return null;
    }

    // ios_phone, ios_pad, android_phone, android_pad
    return platform.split("_");
  };

  /* Get current device type
   *
   * Returns String 'iphone', 'ipad', 'android' or 'unknown'
   */
  DeviceBridge.deviceType = function() {
    // If posible use platform
    if (navigator.userAgent.match(/iPhone/i)) {
      return "iphone";
    }
    if (navigator.userAgent.match(/iPad/i)) {
      return "ipad";
    }
    if (navigator.userAgent.match(/Android/i)) {
      return "android";
    }

    return "unknown";
  };

  /* Get current device css class
   *
   * This is kind of a hack and should be migrated from (to not using
   * a css class as all the latest versions of all clients supports
   * the same content
   *
   * Returns String 'iphone', 'ipad', 'android' or 'unknown'
   */
  DeviceBridge.deviceCSS = function() {
    var deviceType = DeviceBridge.deviceType();
    if (deviceType === "ipad" && apiVersion > 2) {
      return "iphone";
    }

    return deviceType;
  };

  /* Get OS version,
   *
   * Note: To check OS, use deviceType, since this is just the version
   * Returns float with OS version ig: { major: 6, minor: 0 } or undefined for unknown.
   */
  DeviceBridge.osVersion = function() {
    var junk, major, minor;

    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i)
    ) {
      junk = navigator.userAgent.match("OS ([0-9]+)_([0-9]+)");
      major = junk[1];
      minor = junk[2];
      return { major: major, minor: minor };
    } else if (navigator.userAgent.match(/Android/i)) {
      junk = navigator.userAgent.match("Android ([.|0-9]+);");
      var versions = junk[1].split(".");
      major = versions[0];
      minor = versions[1];
      return { major: major, minor: minor };
    }
    return undefined;
  };

  return DeviceBridge;
})();
