/**
 * Routes.js
 * Handle all routes
 */
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Loadable from "react-loadable";

import { AUTH_TOKEN } from "../constants";
import Loading from "components/Loading";

function isLoggedIn() {
  return localStorage.getItem(AUTH_TOKEN);
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      // Check if we are signed in
      isLoggedIn() ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

// Dynamic import route
const getComponent = name => {
  return import("routes/" + name);
};
// Dynamic load route
// https://github.com/jamiebuilds/react-loadable
const loadablePage = name => {
  return Loadable({
    loader: () => getComponent(name),
    loading: props => {
      if (props.error) {
        return <h1>Error loading page</h1>;
      } else if (props.pastDelay) {
        // Show loader after 200ms
        return <Loading />;
      }
      return null;
    }
  });
};

const Routes = () => {
  return (
    <Router>
      <div>
        <Switch>
          <PrivateRoute exact path="/" component={loadablePage("home/Home")} />

          {/* Matches from status */}
          <PrivateRoute
            exact
            path="/matches/:status"
            component={loadablePage("matches/Matches")}
          />

          {/* Startup */}
          <PrivateRoute
            exact
            path="/startup"
            component={loadablePage("startup/Startup")}
          />
          <PrivateRoute
            path="/startup/details"
            component={loadablePage("startup/StartupDetails")}
          />
          <PrivateRoute
            path="/startup/colors"
            component={loadablePage("startup/StartupColors")}
          />

          {/* Create */}
          <PrivateRoute
            path="/create/match"
            component={loadablePage("create/match/CreateMatch")}
          />
          <PrivateRoute
            path="/create/team"
            component={loadablePage("create/team/CreateTeam")}
          />
          <PrivateRoute
            path="/create/tournament"
            component={loadablePage("create/tournament/CreateTournament")}
          />

          {/* Settings */}
          <PrivateRoute
            exact
            path="/settings"
            component={loadablePage("settings/Settings")}
          />
          <PrivateRoute
            path="/settings/tournaments/:id"
            component={loadablePage("settings/tournaments/SettingsTournaments")}
          />
          <PrivateRoute
            path="/settings/colors/:id"
            component={loadablePage("settings/colors/SettingsColors")}
          />
          <PrivateRoute
            path="/settings/share/:id"
            component={loadablePage("settings/share/SettingsShare")}
          />
          <PrivateRoute
            path="/settings/invite/:id"
            component={loadablePage("settings/invite/SettingsInvite")}
          />
          <PrivateRoute
            path="/settings/language"
            component={loadablePage("settings/language/SettingsLanguage")}
          />

          {/* Team */}
          <PrivateRoute
            exact
            path="/team/:teamId/squad"
            component={loadablePage("team/TeamSquad")}
          />
          {/* Player */}
          <PrivateRoute
            exact
            path="/player/:playerId"
            component={loadablePage("player/PlayerEdit")}
          />

          {/* Multiball */}
          <PrivateRoute
            path="/multiball/match/:forzaId"
            component={loadablePage("multiball/MultiballMatch")}
          />

          {/* Livematch */}
          <PrivateRoute
            exact
            path="/match/:id"
            component={loadablePage("match/Match")}
          />
          <PrivateRoute
            exact
            path="/match/:matchId/tournament/:tournamentId"
            component={loadablePage("match/tournament/ChangeTournament")}
          />
          <PrivateRoute
            exact
            path="/match/:id/date"
            component={loadablePage("match/date/ChangeDate")}
          />
          <PrivateRoute
            exact
            path="/match/:id/length"
            component={loadablePage("match/length/HalftimeLength")}
          />
          <PrivateRoute
            exact
            path="/match/:id/result"
            component={loadablePage("match/result/Result")}
          />
          <PrivateRoute
            exact
            path="/match/:id/lineup/:teamId"
            component={loadablePage("match/lineup/Lineup")}
          />
          <PrivateRoute
            exact
            path="/match/:id/lineup/:teamId/starters"
            component={loadablePage("match/lineup/LineupStarters")}
          />
          <PrivateRoute
            exact
            path="/match/:id/lineup/:teamId/substitutes"
            component={loadablePage("match/lineup/LineupSubstitutes")}
          />
          <PrivateRoute
            exact
            path="/match/:id/lineup/:teamId/formation"
            component={loadablePage("match/lineup/LineupFormation")}
          />
          <PrivateRoute
            exact
            path="/match/:id/lineup/:teamId/positions"
            component={loadablePage("match/lineup/LineupPositions")}
          />
          <PrivateRoute
            exact
            path="/match/:id/report"
            component={loadablePage("match/report/Report")}
          />
          <PrivateRoute
            exact
            path="/match/:id/report/state"
            component={loadablePage("match/report/ReportMatchState")}
          />
          <PrivateRoute
            exact
            path="/match/:id/report/goal"
            component={loadablePage("match/report/ReportGoal")}
          />
          <PrivateRoute
            exact
            path="/match/:id/report/card"
            component={loadablePage("match/report/ReportCard")}
          />
          <PrivateRoute
            exact
            path="/match/:id/report/subs"
            component={loadablePage("match/report/ReportSubs")}
          />
          <PrivateRoute
            exact
            path="/match/:id/report/injury"
            component={loadablePage("match/report/ReportInjury")}
          />
          <PrivateRoute
            exact
            path="/match/:id/report/penaltycall"
            component={loadablePage("match/report/ReportPenaltyCall")}
          />
          <PrivateRoute
            exact
            path="/match/:id/report/penaltymiss"
            component={loadablePage("match/report/ReportPenaltyMiss")}
          />
          <PrivateRoute
            exact
            path="/match/:id/report/posthit"
            component={loadablePage("match/report/ReportPostShot")}
          />
          <PrivateRoute
            exact
            path="/match/:id/report/crossbarhit"
            component={loadablePage("match/report/ReportCrossbarShot")}
          />
          <PrivateRoute
            exact
            path="/match/:id/report/penalty-shootout"
            component={loadablePage("match/report/ReportPenaltyShootout")}
          />
          <PrivateRoute
            exact
            path="/match/:id/event/:eventId/edit"
            component={loadablePage("match/event/EditEvent")}
          />
          <PrivateRoute
            exact
            path="/match/:id/event/:eventId/remove"
            component={loadablePage("match/event/RemoveEvent")}
          />
          <PrivateRoute
            exact
            path="/match/:id/stats"
            component={loadablePage("match/stats/EditStats")}
          />

          {/* Public routes */}
          <Route
            path="/reset-password/:token"
            component={loadablePage("reset-password/ResetPassword")}
          />
          <Route
            path="/forgot"
            component={loadablePage("forgot/ForgotPassword")}
          />
          <Route path="/signup" component={loadablePage("signup/Signup")} />
          <Route
            path="/login"
            render={props => {
              if (!isLoggedIn()) {
                const Login = loadablePage("login/Login");
                return <Login {...props} />;
              } else {
                return <Redirect to="/" />;
              }
            }}
          />
          <Route path="*" component={loadablePage("error/Error")} />
        </Switch>
      </div>
    </Router>
  );
};

export default Routes;
