/**
 * StateHandler.js
 * Stores some global variables that can be used from containers/components
 */

const StateHandler = {};

StateHandler.userId = null; // Clubsourcing user id
StateHandler.forzaId = null; // Forza user id
StateHandler.deviceId = null; // Device id (push token)

StateHandler.isApp = false; // true/false - true if we have userKey parameter
StateHandler.platform = ""; // if inApp ios_iphone or android_tablet
StateHandler.devicePlatform = ""; // ios, android or web

StateHandler.locale = null; // en_SE - from locale parameter
StateHandler.lang = null; // en - fallback to navigator language if locale is null

export default StateHandler;
