import Api from "api/Api";

const query = `
  mutation updateUser(
    $userId: ID!
    $forzaId: String
    $deviceId: String
    $platform: String
  ) {
    updateUser(
      userId: $userId
      forzaId: $forzaId
      deviceId: $deviceId
      platform: $platform
    ) {
      id
    }
  }
`;

export const apiUpdateUser = (data, callback) => {
  Api.mutation({
    name: "USER_UPDATE_MUTATION",
    data: { variables: data, query: query },
    callback
  });
};
