/**
 * App.js
 * Setup translations and tracking
 */
import React, { Component } from "react";

import { AUTH_TOKEN, USER_ID, PAGE_REFERRER, LANGUAGE } from "./constants";

import i18n from "./i18n";
import ViewModelBase from "./i18n/ViewModelBase";
import { setMomentLocale } from "./i18n/setMomentLocale";

import { apiMe } from "api/query/me";
import { apiUpdateUser } from "api/mutation/user_update";

import ExtractParam from "utils/ExtractParam";
import DeviceBridge from "utils/DeviceBridge";
import StateHandler from "utils/StateHandler";
import Utils from "utils/Utils";
import Tracking from "utils/Tracking";

import Routes from "routes/Routes";
import Loading from "components/Loading";

class App extends Component {
  constructor() {
    super();
    this.initSetup();
    this.state = { loading: true };
  }
  initSetup() {
    // Handle parameters
    StateHandler.isApp = ExtractParam("isApp") ? true : false;
    const forzaId = ExtractParam("userKey");
    if (forzaId) {
      StateHandler.forzaId = forzaId;
      StateHandler.isApp = true;
      // Get push token from app
      window.pushTokenCallback = token => {
        StateHandler.deviceId = token;
      };
      DeviceBridge.getPushToken();
    }

    StateHandler.locale = ExtractParam("locale");

    StateHandler.platform = ExtractParam("platform");
    if (StateHandler.platform) {
      try {
        const device = StateHandler.platform.split("_")[0];
        StateHandler.devicePlatform = device;
      } catch (e) {}
    } else {
      StateHandler.devicePlatform = "web";
    }
    document.body.classList.add(StateHandler.devicePlatform);

    // From Settings language
    // otherwise from locale parameter or browser navigator
    StateHandler.lang = localStorage.getItem(LANGUAGE) || Utils.getLanguage();

    if (Utils.isTouchDevice()) {
      document.body.classList.add("touch");
      // Set touchstart so we can use :active in css
      document.addEventListener("touchstart", function() {}, true);
    } else {
      document.body.classList.add("no-touch");
    }
  }

  async componentDidMount() {
    // Translations
    i18n.addResourceBundle(
      StateHandler.lang,
      "translations",
      await ViewModelBase.getTranslations(StateHandler.lang)
    );
    i18n.changeLanguage(StateHandler.lang);

    await setMomentLocale(StateHandler.lang);

    // Update user data if inside Forza app
    // or get userId (for tracking) if not found in localStorage
    if (this.isLoggedIn() && (StateHandler.forzaId || !this.getUserId())) {
      this.loadUser();
    } else {
      this.setupTracking();
    }
  }
  isLoggedIn() {
    return localStorage.getItem(AUTH_TOKEN);
  }
  getUserId() {
    return localStorage.getItem(USER_ID) || "";
  }
  loadUser() {
    apiMe(res => {
      if (res.success && res.data && res.data.me) {
        const { id, forzaId, deviceId, platform } = res.data.me;
        localStorage.setItem(USER_ID, id);
        // Update user data if inside Forza app
        if (
          StateHandler.forzaId &&
          (StateHandler.forzaId !== forzaId ||
            StateHandler.deviceId !== deviceId ||
            StateHandler.devicePlatform !== platform)
        ) {
          this.updateUser();
        } else {
          this.setupTracking();
        }
      } else {
        this.setupTracking();
      }
    });
  }
  updateUser() {
    const variables = {
      userId: this.getUserId(),
      forzaId: StateHandler.forzaId
    };
    if (StateHandler.deviceId) {
      variables.deviceId = StateHandler.deviceId;
    }
    if (StateHandler.devicePlatform) {
      variables.platform = StateHandler.devicePlatform;
    }
    apiUpdateUser(variables, res => {
      this.setupTracking();
    });
  }
  setupTracking() {
    // Setup Tracking on page load
    StateHandler.userId = this.getUserId();
    Tracking.init(StateHandler.userId);
    Tracking.trackEvent("NewSession", {
      user_id: StateHandler.userId,
      referrer: localStorage.getItem(PAGE_REFERRER) || ""
    });
    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) return <Loading />;

    return <Routes />;
  }
}

export default App;
