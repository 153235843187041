import Api from "api/Api";

const query = `
  query me {
    me {
      id
      didViewStartupGuide
      forzaId
      deviceId
      platform
      facebookPages {
        pageId
      }
      teams {
        id
      }
    }
  }
`;

const apiMe = callback => {
  Api.query({
    name: "GET_ME_QUERY",
    data: { query: query },
    callback
  });
};

export { apiMe };
