import axios from "axios";

import { AUTH_TOKEN } from "constants.js";
import Tracking from "utils/Tracking";

let Api = {};

// Graphql url
Api.base = process.env.REACT_APP_API_HTTP_URL;

// In development - Use pathname (localhost or ip)
if (Api.base.indexOf("localhost") !== -1) {
  Api.base = Api.base.replace("localhost", window.location.hostname);
}

// If we want to cache responses
// Api.cache = {};

Api.getHeaders = (extraHeaders = {}) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
      ...extraHeaders
    }
  };
};

Api.query = options => {
  const { name, data, callback } = options;

  // Return cache direct if we have it already
  // if (Api.cache[name]) {
  //   callback({ success: true, data: Api.cache[name] });
  // }

  // Milliseconds start loading ajax request
  const trackingStartTime = Date.now();

  // Ajax request
  axios
    .post(Api.base, data, Api.getHeaders())
    .then(res => {
      // Response callback
      callback({ success: true, data: res.data.data });

      // Cache response
      // Api.cache[name] = res.data.data;

      // Track slow ajax requests
      const trackingEndTime = Date.now() - trackingStartTime;
      if (trackingEndTime > 500) {
        Tracking.trackEvent("ViewLoadedTime", {
          page: name,
          ms_time_to_load: trackingEndTime
        });
      }
    })
    .catch(error => {
      // Response callback
      callback({ success: false });
      // Track error
      Tracking.trackEvent("Error", {
        page: name,
        message: error.message || ""
      });

      // Delete cache
      // if (Api.cache[name]) delete Api.cache[name];
    });
};

Api.mutation = options => {
  const { name, data, headers, callback } = options;
  // Milliseconds start ajax request
  const trackingStartTime = Date.now();
  // Ajax request
  axios
    .post(Api.base, data, Api.getHeaders(headers))
    .then(res => {
      // Response callback
      if (!res.data.errors) {
        callback({ success: true, data: res.data.data });
      } else {
        callback({ success: false, errors: res.data.errors });
      }

      // Track slow ajax requests
      const trackingEndTime = Date.now() - trackingStartTime;
      if (trackingEndTime > 500) {
        Tracking.trackEvent("ViewLoadedTime", {
          page: name,
          ms_time_to_load: trackingEndTime
        });
      }
    })
    .catch(error => {
      // Response callback
      callback({ success: false });
      // Track error
      Tracking.trackEvent("Error", {
        page: name,
        message: error.message || ""
      });
    });
};

// https://moonhighway.com/how-the-upload-scalar-works
Api.mutationWithUpload = options => {
  const { variables } = options.data;
  const map = {};
  const files = {};

  // Check if any key is a File
  for (const key in variables) {
    if (variables.hasOwnProperty(key)) {
      const variable = variables[key];
      if (isFile(variable)) {
        const index = Object.keys(map).length;
        // This map is used to associate the file saved in the body of the request
        map[index.toString()] = ["variables." + key];
        // Temp store the file
        files[index] = variable;
      }
    }
  }

  // The FormData constructor builds a multipart/form-data request body
  // Here we add the operation, map, and file(s) to upload
  const form = new FormData();
  form.append("operations", JSON.stringify(options.data));
  form.append("map", JSON.stringify(map));
  for (const key in files) {
    if (files.hasOwnProperty(key)) {
      form.append(key, files[key]);
    }
  }

  // Ajax request
  Api.mutation({
    name: options.name,
    data: form,
    headers: { "content-type": "multipart/form-data" },
    callback: options.callback
  });
};

const isFile = variable => {
  if (variable && variable instanceof Blob) {
    return true;
  }
  return false;
};

export default Api;
