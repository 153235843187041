/* Schema
 * Based on: https://github.com/forzafootball/log-schema/blob/master/logschema/schemas_clubsourcing.py
 * event_name: { attributes }
 */

let TrackingLogSchema = {};

TrackingLogSchema.schema = {
  ViewLoaded: {
    page: { type: "string", required: true },
    referrer: { type: "string", required: false }
  },
  ViewLoadedTime: {
    page: { type: "string", required: true },
    ms_time_to_load: { type: "number", required: true }
  },
  ButtonTap: {
    context: { type: "string", required: true },
    name: { type: "string", required: true }
  },
  // UserInfo - Not in use atm
  UserInfo: {
    teams: { type: "string", required: false }, // team_ids user controls, comma separated string
    is_facebook_connected: { type: "boolean", required: false }
  },
  NewSession: {
    user_id: { type: "string", required: true },
    referrer: { type: "string", required: false }
  },
  NewUser: {
    user_id: { type: "string", required: true } // first time user logs in
  },
  Error: {
    page: { type: "string", required: true }, // the page
    message: { type: "string", required: true } // event message
  },

  // Specific event
  MatchCreated: {
    match_id: { type: "string", required: true },
    tournament_id: { type: "string", required: true },
    team1_id: { type: "string", required: true },
    team2_id: { type: "string", required: true },
    kickoff_time: { type: "number", required: true } // timestamp of match
  },
  MatchDeleted: {
    match_id: { type: "string", required: true },
    kickoff_time: { type: "number", required: true }
  },
  LineupCreated: {
    lineup_id: { type: "string", required: true },
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    lineup_players: { type: "string", required: false },
    subs_players: { type: "string", required: false },
    formation: { type: "string", required: false },
    have_positions: { type: "boolean", required: false }
  },
  LineupEdited: {
    lineup_id: { type: "string", required: true },
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    lineup_players: { type: "string", required: false },
    subs_players: { type: "string", required: false },
    formation: { type: "string", required: false },
    have_positions: { type: "boolean", required: false }
  },
  PlayerCreated: {
    team_id: { type: "string", required: true },
    player_id: { type: "string", required: true },
    first_name: { type: "string", required: true },
    last_name: { type: "string", required: true },
    shirt_number: { type: "number", required: false },
    image_url: { type: "string", required: false }, // url to image
    position: { type: "string", required: false }
  },
  PlayerEdited: {
    team_id: { type: "string", required: true },
    player_id: { type: "string", required: true },
    first_name: { type: "string", required: true },
    last_name: { type: "string", required: true },
    shirt_number: { type: "number", required: false },
    image_url: { type: "string", required: false }, // url to image
    position: { type: "string", required: false },
    height: { type: "number", required: false },
    weight: { type: "number", required: false },
    birthdate: { type: "string", required: false },
    country_id: { type: "string", required: false },
    preferred_foot: { type: "string", required: false },
    sex: { type: "string", required: false }
  },
  PlayerDeleted: {
    team_id: { type: "string", required: true },
    player_id: { type: "string", required: true }
  },
  MatchStarted: {
    match_id: { type: "string", required: true },
    kickoff_time: { type: "number", required: true }, // timestamp
    type: { type: "string", required: true }, // start/halftime
    share_to_facebook: { type: "boolean", required: true },
    social_media_message: { type: "string", required: false }
  },
  MatchStopped: {
    match_id: { type: "string", required: true },
    type: { type: "string", required: true }, // halftime/fulltime
    current_score: { type: "string", required: true }, // e.g. 1-1
    share_to_facebook: { type: "boolean", required: true },
    social_media_message: { type: "string", required: false }
  },
  MatchResult: {
    match_id: { type: "string", required: true },
    fulltime_score: { type: "string", required: false },
    halftime_score: { type: "string", required: false },
    is_goalscorers: { type: "boolean", required: false }
  },
  // share - when a user click "copy url"
  MatchShare: {
    match_id: { type: "string", required: true },
    match_state: { type: "string", required: false }
  },
  MatchViewed: {
    match_id: { type: "string", required: true }
  },
  ChangeStats: {
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    match_state: { type: "string", required: true },
    type: { type: "string", required: true }, // possession, shots, corners
    new_value: { type: "number", required: true },
    is_increased: { type: "boolean", required: true }
  },
  Goal: {
    event_id: { type: "string", required: true },
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    match_minute: { type: "number", required: true },
    match_minute_added: { type: "number", required: false },
    is_edit: { type: "boolean", required: true },
    own_goal: { type: "boolean", required: true },
    penalty: { type: "boolean", required: true },
    lineup_player_scoring: { type: "string", required: false }, // player_id
    lineup_player_assisting: { type: "string", required: false }, // player_id
    share_to_facebook: { type: "boolean", required: true },
    social_media_message: { type: "string", required: false }
  },
  Injury: {
    event_id: { type: "string", required: true },
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    match_minute: { type: "number", required: true },
    match_minute_added: { type: "number", required: false },
    is_edit: { type: "boolean", required: true },
    lineup_player: { type: "string", required: true },
    share_to_facebook: { type: "boolean", required: true },
    social_media_message: { type: "string", required: false }
  },
  Card: {
    event_id: { type: "string", required: true },
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    match_minute: { type: "number", required: true },
    match_minute_added: { type: "number", required: false },
    is_edit: { type: "boolean", required: true },
    lineup_player: { type: "string", required: true },
    card_type: { type: "string", required: true }, // yellow/red/yellowred
    share_to_facebook: { type: "boolean", required: true },
    social_media_message: { type: "string", required: false }
  },
  Substitution: {
    event_id: { type: "string", required: true },
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    match_minute: { type: "number", required: true },
    match_minute_added: { type: "number", required: false },
    is_edit: { type: "boolean", required: true },
    lineup_player_in: { type: "string", required: true },
    lineup_player_out: { type: "string", required: true },
    share_to_facebook: { type: "boolean", required: true },
    social_media_message: { type: "string", required: false }
  },
  PostHit: {
    event_id: { type: "string", required: true },
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    match_minute: { type: "number", required: true },
    match_minute_added: { type: "number", required: false },
    is_edit: { type: "boolean", required: true },
    lineup_player: { type: "string", required: true },
    share_to_facebook: { type: "boolean", required: true },
    social_media_message: { type: "string", required: false }
  },
  CrossbarHit: {
    event_id: { type: "string", required: true },
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    match_minute: { type: "number", required: true },
    match_minute_added: { type: "number", required: false },
    is_edit: { type: "boolean", required: true },
    lineup_player: { type: "string", required: true },
    share_to_facebook: { type: "boolean", required: true },
    social_media_message: { type: "string", required: false }
  },
  PenaltyCall: {
    event_id: { type: "string", required: true },
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    match_minute: { type: "number", required: true },
    match_minute_added: { type: "number", required: false },
    is_edit: { type: "boolean", required: true },
    share_to_facebook: { type: "boolean", required: true },
    social_media_message: { type: "string", required: false }
  },
  PenaltyMiss: {
    event_id: { type: "string", required: true },
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    match_minute: { type: "number", required: true },
    match_minute_added: { type: "number", required: false },
    is_edit: { type: "boolean", required: true },
    lineup_player: { type: "string", required: false },
    share_to_facebook: { type: "boolean", required: true },
    social_media_message: { type: "string", required: false }
  },
  PenaltyShootout: {
    event_id: { type: "string", required: true },
    match_id: { type: "string", required: true },
    team_id: { type: "string", required: true },
    is_scored: { type: "boolean", required: true },
    is_edit: { type: "boolean", required: true },
    lineup_player: { type: "string", required: false },
    share_to_facebook: { type: "boolean", required: true },
    social_media_message: { type: "string", required: false }
  },
  EventDeleted: {
    event_id: { type: "string", required: true },
    match_id: { type: "string", required: true }
  },
  SetupSocialMedia: {
    team_id: { type: "string", required: true },
    social_network: { type: "string", required: true },
    successful_connection: { type: "boolean", required: true }
  }
};

export default TrackingLogSchema;
